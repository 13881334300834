import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { LoggingService } from '../logging.service';

@Component({
  selector: 'bkp-logging',
  template: `
    <div class="msg-color">{{logMsg}}</div>
  `,
  styles: [`
      .msg-color {
          color: red;
      }
  `]
})
export class LoggingComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  logMsg: any;

  constructor(private log: LoggingService) { }

  ngOnInit() {
    this.subscription = this.log.getLogMessages().subscribe(msg => {
      this.logMsg = msg;
      console.log(msg);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
