import { Component } from '@angular/core';
import { SuiModalService } from 'ng2-semantic-ui';

import { AuLLogoUrl, BKPLogoUrl, Title } from '../const.exports';

import { SiteNoticeModal } from './infos/site-notice/site-notice.component';
import { AboutUsModal } from './infos/about-us/about-us.component';

@Component({
  selector: 'bkp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = Title;
  bkpLogoUrl = BKPLogoUrl;
  aulLogoUrl = AuLLogoUrl;

  constructor(private modalService: SuiModalService) {
  }

  showSiteNotice() {
    this.modalService.open(new SiteNoticeModal());
  }

  showAboutUs() {
    this.modalService.open(new AboutUsModal());
  }

}
