import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  private logSubject: Subject<any> = new Subject();

  getLogMessages(): Observable<any> {
    return this.logSubject.asObservable();
  }

  info(msg: any) {
    this.logSubject.next(msg);
  }

  warn(msg: any) {
    this.logSubject.next(msg);
  }

  error(msg: any, cb?: () => any) {
    this.logSubject.next(msg);
    if (cb) {
      timer(5000).subscribe(i => cb());
    }
  }
}
