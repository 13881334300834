import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';

// import * as uuidv4 from 'uuid/v4';
// import * as uuidv5 from 'uuid/v5';

import { LocalStorageService } from './local-storage.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private localStorageService: LocalStorageService,
              private log: LoggingService) { }

  // public generateUUID(data?, namespaceUUID?) {
  //   return (data && namespaceUUID) ? uuidv5(data, namespaceUUID) : uuidv4();
  // }
  //
  // public formatTS(ts: number): string {
  //   return new Date(ts * 1000).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  // }
  //
  // public currentTS(date?: Date) {
  //   return Math.floor((date ? date.getTime() : Date.now()) / 1000);
  // }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
      this.log.error('An error occurred: ' + error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
      this.log.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
