import { Component } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from 'ng2-semantic-ui';
import {
  AlphaDekadeLogoUrl,
  AuLBBLogoUrl,
  AuLLogoUrl,
  BKPLogoUrl, BMBFLogoUrl,
  DeployedOn, KHuFLogoUrl,
  Title,
  Version
} from '../../../const.exports';

export class SiteNoticeModal extends ComponentModalConfig<undefined> {
  constructor() {
    super(SiteNoticeComponent);

    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Large;
  }
}

@Component({
  selector: 'bkp-site-notice',
  templateUrl: './site-notice.component.html'
})
export class SiteNoticeComponent {

  title = Title;
  version = Version;
  deployedOn = DeployedOn;

  bkpLogoUrl = BKPLogoUrl;
  aulLogoUrl = AuLLogoUrl;
  aulBBLogoUrl = AuLBBLogoUrl;
  alphaDekadeLogoUrl = AlphaDekadeLogoUrl;
  bmbfLogoUrl = BMBFLogoUrl;
  khufLogoUrl = KHuFLogoUrl;

  constructor(public modal: SuiModal<undefined>) {
  }
}
