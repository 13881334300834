import { Component } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from 'ng2-semantic-ui';

import { Title, Version, DeployedOn } from '../../../const.exports';

export class AboutUsModal extends ComponentModalConfig<undefined> {
  constructor() {
    super(AboutUsComponent);

    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Small;
  }
}

@Component({
  selector: 'bkp-about-us',
  templateUrl: './about-us.component.html',
  styles: [`
      .ui.page.modals.transition.visible {
          display: flex !important;
      }
  `]
})
export class AboutUsComponent {

  title = Title;
  version = Version;
  deployedOn = DeployedOn;

  constructor(public modal: SuiModal<undefined>) {
  }
}
