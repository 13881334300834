import { environment } from './environments/environment';

export const Title = 'BasisKomPlus';
export const Version = '0.15.0';
export const DeployedOn = '30.06.2020 12:30 Uhr';

export const GraphQLUri = 'https://jtj7wonpijcyhfukdaaremjjy4.appsync-api.eu-central-1.amazonaws.com/graphql';
export const XApiKey = 'da2-ig6ifotemjhgnlld72mngvlhjq';

export const AlphaDekadeLogoUrl = '/assets/images/logo-alpha-dekade.png';
export const AuLLogoUrl = '/assets/images/logo-aul.png';
export const AuLBBLogoUrl = '/assets/images/logo-aul-bb.png';
export const BKPLogoUrl = '/assets/images/logo-bkp.png';
export const BMBFLogoUrl = '/assets/images/logo-bmbf.svg';
export const KHuFLogoUrl = '/assets/images/logo-khuf.png';
